import { createApp } from 'vue'
import * as Sentry from "@sentry/vue";

import App from './App.vue'
import { app as firebaseApp, analytics } from '@/firebase/firebase_config'
import firebaseReportHandler from './firebase/firebase_report_handler'
import { SENTRY_DSN, SENTRY_ENV } from '@/settings'

const app = createApp(App)

Sentry.init({
  app,
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV,
});

app.provide('reportHandler', firebaseReportHandler)

app.mount('#app')

app.config.globalProperties.$firebase = firebaseApp
app.config.globalProperties.$analytics = analytics

import { logEvent } from 'firebase/analytics'
import { analytics } from '@/firebase/firebase_config'
import type { ReportHandler } from '@/reporting/report_handler'
import type { AnalyticsEvent } from '@/reporting/analytics_event'

export class FirebaseReportHandler implements ReportHandler {
  logEvent(event: AnalyticsEvent) {
    logEvent(analytics, event.tag, event.extras)
  }
}

const firebaseReportHandler = new FirebaseReportHandler()
export default firebaseReportHandler

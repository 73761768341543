<script lang="ts" setup>
import { computed } from 'vue'
import { errorHelpStore } from '@/stores/error'

const errorState = computed(() => !!errorHelpStore.errorState)
const errorMessage = computed(() => errorHelpStore.errorState ? errorHelpStore.errorMessage : '' )

</script>

<template>
  <header :class="{ active: errorState }">
    <div class="container-fluid">
      <div class="row header">
        <div class="d-flex align-items-center">
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
$header-height: 50px;
$header-logo-height: 24px;
header {
  height: 0px;
  max-width: $container-max-width;
  margin: 0 auto;
  color: white;
  background-color: black;
  transition: height 0.5s;
  overflow: hidden;
  &.active {
    height: $header-height;
  }

}
</style>

import { reactive, toRaw } from "vue";
import type { WeekElement } from "@/components/utils/date";

export const weekStore = reactive({
  selectedWeeks: [] as Array<WeekElement>,
  MAX_WEEKS: 12,
  toggle(week:WeekElement) {
    if (this.selectedWeeks.map(wk => wk.weekNumber).includes(week.weekNumber)) {
      this.selectedWeeks = this.selectedWeeks.filter(v => v.weekNumber !== week.weekNumber);
    } else {
      this.selectedWeeks.push(week);
    }
  },
  setWeeks(weeks:Array<WeekElement>) {
    this.selectedWeeks = [...weeks];
  },
  value() {
    return toRaw(this.selectedWeeks)
  }
})

import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import {
  FB_API_KEY, FB_APP_ID,
  FB_AUTH_DOMAIN,
  FB_MEASUREMENT_ID,
  FB_MESSAGE_SENDER,
  FB_PROJECT_ID,
  FB_STORAGE_BUCKET
} from '@/settings'

const firebaseConfig = {
  apiKey: FB_API_KEY,
  authDomain: FB_AUTH_DOMAIN,
  projectId: FB_PROJECT_ID,
  storageBucket: FB_STORAGE_BUCKET,
  messagingSenderId: FB_MESSAGE_SENDER,
  appId: FB_APP_ID,
  measurementId: FB_MEASUREMENT_ID
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

export { app, analytics }

<script setup lang="ts">
import type { WeekElement } from '@/components/utils/date'
import { weekStore } from '@/stores/week'

const props = defineProps<{
  week: WeekElement
}>()

const remove = () => {
  weekStore.toggle(props.week)
}
</script>

<template>
  <button class="week-pill" @click="remove" test-tag="selected-cw-pill">
    <div class="week">Woche {{ props.week.weekNumber }}</div>
  </button>
</template>

<style lang="scss" scoped>
button.week-pill {
  color: black;
  border: 0;
  border-radius: 10px;
  background: #ebebeb;
  padding: 2px 12px 2px 28px;
  position: relative;
  font-weight: 500;
  @include set-font-size($t-fz-13, $t-fz-13, $t-fz-12);
  &:hover {
    background-color: #e3e3e3;
  }
  &:after {
    content: ' ';
    position: absolute;
    width: 10px;
    height: 10px;
    top: calc(50% - 5px);
    left: 10px;
    background-image: url('data:image/svg+xml,<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.8121 4.99999L10 0.812101L9.1879 0L5 4.18789L0.812103 0L0 0.812101L4.1879 4.99999L0 9.18788L0.812103 9.99998L5 5.81209L9.1879 9.99998L10 9.18788L5.8121 4.99999Z" fill="black"/></svg>');
  }
}
</style>

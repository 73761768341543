import { reactive } from "vue";

export enum ErrorState {
  NO_ERROR = 0,
  BACKEND_ERROR = 1,
  CRITICAL_ERROR = 2
}

export const errorHelpStore = reactive({
  errorMessage: '',
  errorState: ErrorState.NO_ERROR,
  showHelpTextContent: false,
})

<script setup lang="ts">
import { format } from 'date-fns'
import { de } from 'date-fns/locale'

import type { WeekElement } from '@/components/utils/date'
import { weekStore } from '@/stores/week'

const props = defineProps<{
  week: WeekElement
  selected: boolean
}>()

const startDateText = format(props.week.monday, "dd.'&nbsp;'MMMM", {
  locale: de
})
const endDateText = format(props.week.friday, "dd.'&nbsp;'MMMM", {
  locale: de
})

const dateString = `${startDateText} – ${endDateText}`

const toggle = () => {
  weekStore.toggle(props.week)
}
</script>

<template>
  <button :class="{ selected: selected }" class="week-toggle" @click="toggle" test-tag="cw-selection-week-toggle">
    <div class="checkbox" test-tag='cw-selection-checkbox'></div>
    <div class="week" test-tag='cw-selection-week'>Woche {{ props.week.weekNumber }}</div>
    <div v-html="dateString" class="date" />
  </button>
</template>

<style lang="scss" scoped>
button.week-toggle {
  color: black;
  display: grid;
  overflow: hidden;
  height: 60px;
  border: 0;
  background: white;
  border-radius: 5px;
  grid-template-columns: 36px 82px 1fr;
  justify-items: start;
  align-items: center;
  font-family: $open-sans;
  width: 100%;
  box-shadow: 1px 2px 7px 0px #00000029;
  @media (hover: hover) {
    &:hover {
      background-color: #e3e3e3;
    }
  }

  @include media-breakpoint-up(sm) {
    box-shadow: 1px 2px 9px 0px #00000029;
    grid-template-columns: 20px 115px 1fr;
    padding: 0 15px;
  }
  @include set-font-size($t-fz-13, $t-fz-12, $t-fz-12);
  .checkbox {
    width: 100%;
    height: 100%;
    position: relative;
    &:before {
      content: ' ';
      position: absolute;
      left: calc(50% - 10px);
      top: calc(50% - 8px);
      width: 20px;
      height: 20px;
      background-size: 20px;
      background-image: url('data:image/svg+xml,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="17" height="17" rx="2.5" fill="white" stroke="%23838383"/></svg>');
    }
  }
  &.selected {
    color: #b2a4a3;
    background: #ffebe9;
    @media (hover: hover) {
      &:hover {
        background: #ffddda;
      }
    }
    .checkbox:before {
      background-image: url('data:image/svg+xml,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="17" height="17" rx="2.5" fill="white" stroke="%23838383"/><path fill-rule="evenodd" clip-rule="evenodd" d="M10.072 9L15.6001 3.47197L14.5281 2.39999L9.00004 7.92802L3.472 2.39999L2.40002 3.47197L7.92806 9L2.40002 14.528L3.472 15.6L9.00004 10.072L14.5281 15.6L15.6001 14.528L10.072 9Z" fill="black"/></svg>');
    }
  }

  .week {
    font-weight: 700;
    display: flex;
    justify-content: center;
    width: 85%;
  }
  .date {
    font-weight: 300;
    text-align: left;
    line-height: 18px;
  }
}
</style>
export const CUSTOMER_ABSENCE_ENDPOINT =
  import.meta.env.VITE_CUSTOMER_ABSENCE_ENDPOINT || 'https://backend-staging.thermondo.de/api/customer-absence/'

export const CONTRACT_SIGNATURE_URL =
  import.meta.env.VITE_CONTRACT_SIGNATURE_URL || 'https://auftrag-staging.thermondo.de/'

// Analytics
export const FB_API_KEY =
  import.meta.env.VITE_FIREBASE_API_KEY || 'AIzaSyAwTdHNC4SY4O2CqjxNooSNv7Wmj6Piwq4'
export const FB_AUTH_DOMAIN =
  import.meta.env.VITE_FIREBASE_AUTH_DOMAIN || 'customer-absence-staging.firebaseapp.com'
export const FB_PROJECT_ID =
  import.meta.env.VITE_FIREBASE_PROJECT_ID || 'customer-absence-staging'
export const FB_STORAGE_BUCKET =
  import.meta.env.VITE_FIREBASE_STORAGE_BUCKET || 'customer-absence-staging.appspot.com'
export const FB_MESSAGE_SENDER =
  import.meta.env.VITE_FIREBASE_MESSAGE_SENDER || '510454034645'
export const FB_APP_ID =
  import.meta.env.VITE_FIREBASE_APP_ID || '1:510454034645:web:a71047e40930b9c270325c'
export const FB_MEASUREMENT_ID =
  import.meta.env.VITE_FIREBASE_MEASUREMENT_ID || 'G-RM47BJKZXV'

export const SENTRY_ENV =
  import.meta.env.VITE_SENTRY_ENVIRONMENT || 'development'

export const SENTRY_DSN =
  import.meta.env.VITE_SENTRY_DSN

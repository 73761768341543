<script lang="ts" setup>
import { computed } from 'vue'
import { errorHelpStore } from '@/stores/error'

const showHelpTextContent = computed(() => errorHelpStore.showHelpTextContent)

</script>
<template>
  <div class="help-header" :class="{ active: showHelpTextContent }">
    <div class="container-fluid">
      <div class="row">
        <div class="d-flex align-items-center">
          Damit thermondo für Sie einen passenden Installationstermin finden kann, möchten wir wissen, wann Sie keine Zeit für eine Installation haben. So können wir Ihnen effizient nur die Tage anbieten, die in unserem Kalender auch wirklich frei sind. Je weniger Wochen Sie auswählen, umso schneller können wir für Sie einen Termin finden.
        </div>
      </div>
    </div>
  </div>

</template>

<style lang="scss" scoped>
$header-height: 80px;
$header-logo-height: 24px;
div.help-header {
  height: 0px;
  max-width: $container-max-width;
  margin: 0 auto;
  color: white;
  background-color: black;
  transition: height 0.5s;
  overflow: hidden;
  &.active {
    height: auto;
    padding: 10px 3%;
  }

}
</style>

<script setup lang="ts">
import { computed, inject } from 'vue'
import { weekStore } from '@/stores/week'
import WeekPill from '@/components/WeekPill.vue'
import { saveWeeksToServer } from '@/components/utils/backend'
import { successStore, SuccessState } from '@/stores/success'
import { submissionButtonClicked } from '@/firebase/events'
import type { ReportHandler } from '@/reporting/report_handler'

const report = inject<ReportHandler>('reportHandler')

const sortedWeeks = computed(() =>
  weekStore.selectedWeeks.sort((a, b) => a.monday.getTime() - b.monday.getTime())
)
const saveButtonState = computed(() => {
  return !(successStore.successState === SuccessState.UNSAVED)
})
const successMessage = computed(() => successStore.successMessage)
const successState = computed(() => successStore.successState)

const saveWeeks = () => {
  if (!(sortedWeeks.value.length === weekStore.MAX_WEEKS || saveButtonState.value)) {
    saveWeeksToServer()
  }
  if (report) {
    report.logEvent(submissionButtonClicked)
  }
}
</script>

<template>
  <div class="sticky">
    <div class="info-sidebar-container">
      <h2>Ausgewählte Abwesenheit:</h2>
      <p v-if="sortedWeeks.length === 0">
        Sie haben <strong>alle verfügbaren Wochen Zeit</strong> für eine Installation.
      </p>
      <p v-else-if="sortedWeeks.length === 1">
        In der folgenden Woche haben Sie <strong>keine</strong> Zeit für eine Installation:
      </p>
      <p v-else-if="sortedWeeks.length === weekStore.MAX_WEEKS">
        Bitte setzen Sie <strong>mindestens eine</strong> verfügbare Woche frei.
      </p>
      <p v-else-if="sortedWeeks.length > 1">
        In den folgenden Wochen haben Sie <strong>keine</strong> Zeit für eine Installation:
      </p>
      <div v-if="sortedWeeks.length > 0" class="selected-week-holder">
        <WeekPill v-bind:key="`kw${week.weekNumber}`" v-for="week in sortedWeeks" :week="week" />
      </div>
    </div>
    <div v-if="successState === SuccessState.SAVED" class="success">
      {{ successMessage }}
    </div>
    <button
      class="t-btn-1"
      :class="{ disabled: sortedWeeks.length === weekStore.MAX_WEEKS || saveButtonState }"
      @click="saveWeeks"
    >
      Abwesenheit bestätigen
    </button>
  </div>
</template>

<style lang="scss" scoped>
.sticky {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  @include media-breakpoint-up(lg) {
    padding-left: 0;
  }
  @include media-breakpoint-up(md) {
    position: sticky;
    top: 20px;
    gap: 40px;
  }
}
.info-sidebar-container {
  padding: 0 15px;
  width: 100%;
  margin: 0;
  @include media-breakpoint-up(md) {
    border: 1px solid #dedede;
    padding: 20px;
  }
}
.selected-week-holder {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px 0 8px;
  @include media-breakpoint-up(md) {
    padding: 0;
  }
}
p {
  font-weight: 300;
  @include set-font-size($t-fz-13, $t-fz-12, $t-fz-11);
  display: inline;
  @include media-breakpoint-up(md) {
    display: block;
  }
}
h2 {
  @extend p;
  font-weight: 700;
  display: inline;
  @include media-breakpoint-up(md) {
    display: block;
  }
}
button {
  scale: 100%;
}
strong {
  font-weight: 700;
}
</style>

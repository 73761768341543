<script setup lang="ts">
import HeadlineTitle from '@/components/HeadlineTitle.vue'
import HeadlineElement from '@/components/HeadlineElement.vue'
import HeaderBar from '@/components/HeaderBar.vue'
import ErrorBar from '@/components/ErrorBar.vue'
import HelpBar from '@/components/HelpBar.vue'
import WeekToggle from '@/components/WeekToggle.vue'
import MonthHeader from '@/components/MonthHeader.vue'
import InfoSidebar from '@/components/InfoSidebar.vue'

import { weekStore } from '@/stores/week'
import { monthFromNumber, generateWeekList, type WeekElement } from '@/components/utils/date'

const weekList = generateWeekList(new Date(), weekStore.MAX_WEEKS)
</script>

<template>
  <main>
    <HeaderBar />
    <HeadlineElement />
    <ErrorBar />
    <HelpBar />
    <div class="container-fluid step-loader">
      <HeadlineTitle />
      <div class="columns">
        <div class="week-toggle-list d-flex flex-column">
          <template v-bind:key="`weekToggle-${week.weekNumber}`" v-for="week in weekList">
            <WeekToggle
              v-if="week && week.weekNumber !== 0"
              :week="week as WeekElement"
              :selected="!!weekStore.selectedWeeks.find((wk) => wk.weekNumber === week.weekNumber)"
            />
            <MonthHeader v-else-if="week" :month="monthFromNumber(week.month)" />
          </template>
        </div>
        <div class="info">
          <InfoSidebar />
        </div>
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped>
h3.month:first-child {
  padding-top: 0px;
  height: 16px;
}
.container-fluid {
  padding: 30px 0 0;
  @include media-breakpoint-up(md) {
    padding: 55px 35px 15px;
  }
}
.week-toggle-list {
  gap: 10px;
  padding: 0 15px 52px;
  @include media-breakpoint-up(md) {
    flex-basis: 50%;
    padding: 0 0 48px;
  }
}
div.columns {
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(md) {
    flex-direction: row;
    gap: 30px;
  }
}
@include media-breakpoint-up(md) {
  .week-toggle-list {
    flex-basis: 52%;
  }
}
.info {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  background: white;
  padding-top: 10px;
  padding-bottom: 10px;
  position: sticky;
  bottom: 0;
  box-shadow: 0px -5px 8px 0px #00000029;
  @include media-breakpoint-up(md) {
    background: none;
    box-shadow: none;
    padding: 0;
    flex-basis: 48%;
    height: 80vh;
  }
}
</style>

<script setup lang="ts">
const props = defineProps<{
  month: string
}>()
</script>

<template>
  <h3 class="month">{{ props.month }}</h3>
</template>

<style lang="scss" scoped>
h3.month {
  height: 26px;
  font-weight: 300;
  padding-top: 10px;
  color: #595B60;
  margin-bottom: 12px;
  @include set-font-size($t-fz-13, $t-fz-13, $t-fz-12);
}
</style>

import { reactive } from "vue";

export enum SuccessState {
  UNSAVED = 0,
  SAVING = 1,
  SAVED = 2
}

export const successStore = reactive({
  successMessage: '',
  successState: SuccessState.UNSAVED
})

<script lang="ts" setup>
import { computed } from 'vue'
import { errorHelpStore } from '@/stores/error'
import HelpSvg from '@/assets/help.svg'
import CloseSvg from '@/assets/close.svg'

const showHelpTextContent = computed(() => errorHelpStore.showHelpTextContent)
</script>

<template>
  <div class="headline__wrapper">
    <div class="container-fluid headline">
      <div>Herzlichen Glückwunsch</div>
      <span class="headline__help-icon" @click="errorHelpStore.showHelpTextContent = !showHelpTextContent">
        <span v-if="showHelpTextContent">
          <CloseSvg />
        </span>
        <span v-else>
          <HelpSvg />
        </span>
      </span>
    </div>
  </div>
</template>

<style lang="scss">
.headline {
  @include set-font-size($t-fz-10, $t-fz-08, $t-fz-07);

  text-align: center;
  color: $t-white;
  margin-bottom: 0;
  position: relative;
  max-width: $container-max-width;

  &__wrapper {
    background-color: $t-coral-red;
    padding-bottom: 16px;
    padding-top: 16px;
    position: relative;
  }

  &__help-icon {
    $icon-size: 22px;

    user-select: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: calc($grid-gutter-width / 2);
    cursor: pointer;
    display: inline-block;
    border: 1px solid $t-white;
    border-radius: 50%;
    height: $icon-size;
    width: $icon-size;
    text-align: center;
    line-height: 16px;

    @include media-breakpoint-up(md) {
      height: $icon-size * 1.4;
      width: $icon-size * 1.4;
      line-height: 26px;
      right: 35px;
    }

    @include media-breakpoint-up(lg) {
      right: 50px;
    }

    svg {
      fill: $t-white;
      stroke: $t-white;
      stroke-width: 2;
      scale: 0.7;

      @include media-breakpoint-up(md) {
        scale: 1;
        stroke-width: 1;
        height: $icon-size * 0.8;
        width: $icon-size * 0.8;
      }
    }
  }
}
</style>

import axios from 'axios';
import { format, addHours } from 'date-fns';
import { getJwtTokenFromUrl } from './jwt';
import { weekStore } from '@/stores/week'
import { ErrorState, errorHelpStore } from '@/stores/error'
import { CUSTOMER_ABSENCE_ENDPOINT, CONTRACT_SIGNATURE_URL } from '@/settings';
import { SuccessState, successStore } from '@/stores/success';

export const saveWeeksToServer = async () => {
  errorHelpStore.errorState = ErrorState.NO_ERROR;
  successStore.successState = SuccessState.SAVING;
  
  const token = getJwtTokenFromUrl();
  if (!token) {
    errorHelpStore.errorState = ErrorState.CRITICAL_ERROR;
    errorHelpStore.errorMessage = "Ein kritischer Fehler ist aufgetreten.";
    return await true;
  }

  const weeks = weekStore.value().map(week => format(addHours(week.monday, 12), 'yyyy-MM-dd'))
  try {
    const res = await axios.post(`${CUSTOMER_ABSENCE_ENDPOINT}?token=${token}`, { weeks })
    switch (res.status) {
      case 200:
      case 201:
        successStore.successState = SuccessState.SAVED;
        successStore.successMessage = "Vielen Dank für Ihre Informationen."
        window.location.replace(`${CONTRACT_SIGNATURE_URL}?token=${token}`);
      break;
    }
  } catch (error) {
    successStore.successState = SuccessState.UNSAVED;
    errorHelpStore.errorState = ErrorState.BACKEND_ERROR;
    // Single error message for our customers. We're logging errors on the backend.
    errorHelpStore.errorMessage = "Es ist ein Fehler beim Speichern Ihrer Auswahl aufgetreten."
 }
}

import { format, getWeek, setWeek, nextMonday, addDays } from 'date-fns'
import { de } from 'date-fns/locale'

type MonthHeader = {
  month: number;
  weekNumber: number;
}

export type WeekElement = {
  monday: Date;
  friday: Date;
} & MonthHeader;

// generate the German language titles for the months, zero indexed
export const monthFromNumber = (monthNumber: number, yearNumber: number = new Date().getFullYear()) =>
  format(new Date(yearNumber, monthNumber, 1), 'LLLL yyyy', {
    locale: de
  }).toUpperCase()

export const generateWeekList = (currentDate: Date, weekCount: number): Array<MonthHeader|WeekElement> => {
  // start week list 4 weeks after we start the
  const startWeek = getWeek(currentDate) + 4
  const startDate = setWeek(nextMonday(new Date(new Date().getFullYear(), 0, 4)), startWeek)
  let currentMonth = startDate.getMonth();
  let weekList:Array<MonthHeader|WeekElement> = [{ month: currentMonth, weekNumber: 0 }];
  [...Array(weekCount)].forEach((v, i) => {
    const monday = addDays(startDate, i * 7)
    const friday = addDays(startDate, i * 7 + 5)
    const week = getWeek(monday)
    if (monday.getMonth() !== currentMonth) {
      weekList = [
        ...weekList,
        {
          month: monday.getMonth(),
          weekNumber: 0
        }
      ];
      currentMonth = monday.getMonth();
    }
    weekList = [...weekList, { monday: monday, friday: friday, weekNumber: week, month: monday.getMonth() }];
  })

  return weekList;
}